import { inject, Injectable } from '@angular/core';
import { BrandService } from 'brand';
import { LanguageService } from 'language';
import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetService } from 'zendesk-webwidget';
@Injectable({
  providedIn: 'root',
})
export class ZendeskChatService extends NgxZendeskWebwidgetConfig {
  private brandService = inject(BrandService);
  private lang = inject(LanguageService);
  override injectionTag = 'body';
  accountUrl = `${this.brandService.brand}.zendesk.com`;
  lazyLoad = true;

  callback(ngxZendeskWebWidgetService: NgxZendeskWebwidgetService) {
    if (ngxZendeskWebWidgetService.isInitialized) {
      ngxZendeskWebWidgetService.zE('webWidget', 'setLocale', this.lang.current);
      ngxZendeskWebWidgetService.zE('webWidget', 'hide');
    }
  }
}
