import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, signal, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { catchError, EMPTY } from 'rxjs';
import { AuthService } from 'auth-data-access';
import { ButtonFormFieldComponent, PasswordStrengthFormFieldComponent } from 'form-field';
import { SvgComponent } from 'icon';
import { ToastService } from 'toast';
import { TranslatePipe, TranslateService } from 'translate';
import { BrowserService } from 'utils';
import { CreatePasswordDisclaimerComponent } from '../create-password-disclaimer/create-password-disclaimer.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslatePipe,
    ButtonFormFieldComponent,
    PasswordStrengthFormFieldComponent,
    SvgComponent,
    CreatePasswordDisclaimerComponent,
  ],
  selector: 'lib-password-form',
  templateUrl: './password-form.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordFormComponent {
  private authService = inject(AuthService);
  private browserService = inject(BrowserService);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private route = inject(ActivatedRoute);
  redirectPath = input<string>();
  titleKey = input<string>();
  password = signal<string>(null);
  loading = signal(false);
  showDisclaimer = signal(true);
  type = input<'create' | 'reset'>('create');
  queryParamsSignal = toSignal(this.route.paramMap);
  refId = computed(() => this.queryParamsSignal()?.get('refId'));

  setPassword($event: string) {
    this.password.set($event);
  }

  back() {
    this.showDisclaimer.set(true);
  }

  hideDisclaimer() {
    this.showDisclaimer.set(false);
  }

  submitPassword() {
    this.loading.set(true);
    this.authService
      .setPassword(this.password())
      .pipe(
        catchError(err => {
          console.error(err);
          const errorCode = err?.error?.error;
          const errorMsg = this.translateService.getTranslation(errorCode ? ['password', errorCode] : ['server_error']);
          this.toastService.add(errorMsg, false);
          this.loading.set(false);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.toastService.add(this.translateService.getTranslation(['password', 'password_set']), true);
        this.loading.set(false);
        this.redirectAfterLogin();
      });
  }

  resetPassword() {
    this.loading.set(true);
    this.authService
      .resetPassword(this.refId(), this.password())
      .pipe(
        catchError(err => {
          console.error(err);
          this.authService.handleSelfcarePassError(err);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.loading.set(false);
        this.toastService.add(this.translateService.getTranslation(['password', 'success']));
        this.redirectAfterLogin();
      });
  }

  redirectAfterLogin() {
    this.browserService.redirect(this.redirectPath() ?? '/account');
  }
}
