<div class="w-full flex justify-between px-4 -mt-3" data-testid="time-remaining">
  <ng-container
    *ngIf="{
      timeRemaining: timerService.timeRemaining$ | async,
    } as $"
  >
    <mat-hint class="text-xs !text-[var(--color-red-light)]" *ngIf="$.timeRemaining === 0; else timer">
      {{ ['login', 'code_expired'] | translate }}
    </mat-hint>
    <ng-template #timer>
      <mat-hint class="text-xs" [ngClass]="{ '!text-white': isWhiteTheme }">
        {{ ['login', 'time_remaining'] | translate }}
      </mat-hint>
    </ng-template>

    <mat-hint
      class="text-xs mr-2"
      [ngClass]="{
        '!text-[var(--color-red-light)]': (timerService.timeRemaining$ | async) === 0,
        '!text-white': isWhiteTheme && (timerService.timeRemaining$ | async) > 0,
      }"
    >
      {{ $.timeRemaining | date: 'mm:ss' }}
    </mat-hint>
  </ng-container>
</div>
