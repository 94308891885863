import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Subscription, takeWhile, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private seconds = 300;
  private timerSubscription: Subscription;
  private timerSubject$ = new BehaviorSubject<number>(this.seconds * 1000);
  public timeRemaining$ = this.timerSubject$.asObservable();

  public startTimer() {
    if (this.timerSubscription && !this.timerSubscription.closed) {
      return; // Timer already started
    }

    this.timerSubscription = timer(0, 1000)
      .pipe(
        map(n => this.seconds - n),
        takeWhile(() => !this.timerSubscription?.closed)
      )
      .subscribe(secondsLeft => {
        this.timerSubject$.next(secondsLeft * 1000);
        if (secondsLeft === 0) {
          this.timerSubscription?.unsubscribe();
        }
      });
  }

  public removeTimer() {
    if (this.timerSubscription && !this.timerSubscription.closed) {
      this.timerSubscription.unsubscribe();
    }
  }
}
