<div class="flex items-center flex-col m-4">
  <lib-svg
    [svg]="brandService.isYallo() ? 'reset-password' : 'reset-password-lebara'"
    class="w-32 [&_svg]:size-full"
  ></lib-svg>
</div>

<div class="flex flex-col items-center mt-12">
  <p class="mt-4 text-center">
    {{ ['password', 'reset_password_disclaimer'] | translate }} <br />
    {{ ['password', 'reset_password_method'] | translate | replaceToken: { method: loginMethodTranslation() } }}
  </p>
</div>
