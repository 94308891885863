import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { BrandService } from 'brand';
import { ButtonFormFieldComponent } from 'form-field';
import { SvgComponent } from 'icon';
import { TranslatePipe } from 'translate';

@Component({
  selector: 'lib-create-password-disclaimer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonFormFieldComponent, TranslatePipe, SvgComponent],
  templateUrl: './create-password-disclaimer.component.html',
})
export class CreatePasswordDisclaimerComponent {
  protected brandService = inject(BrandService);
  skip = output();
  createPassword = output();
}
