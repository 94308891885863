import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslatePipe } from 'translate';
import { TimerService } from '../timer.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-timer',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, TranslatePipe, AsyncPipe],
  templateUrl: './timer.component.html',
})
export class TimerComponent {
  timerService = inject(TimerService);
  @Input() theme: 'normal' | 'white' = 'normal';

  get isWhiteTheme() {
    return this.theme === 'white';
  }
}
