import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslatePipe } from 'translate';
import { PasswordFormComponent } from '../password-form/password-form.component';

@Component({
  selector: 'lib-reset-password',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PasswordFormComponent, TranslatePipe],
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent {}
