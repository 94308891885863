<div class="flex items-center flex-col m-4">
  <lib-svg
    [svg]="brandService.isYallo() ? 'create-password' : 'create-password-lebara'"
    class="w-2/5 [&_svg]:size-full"
  ></lib-svg>
</div>

<p class="text-center">{{ ['password', 'create_password_disclaimer'] | translate }}</p>

<div class="flex flex-col items-center mt-12">
  <lib-button-form-field
    type="submit"
    size="full-width"
    class="w-full lg:col-span-3 lg:pt-1.5"
    (click)="skip.emit()"
    data-testid="skip-button"
  >
    {{ ['password', 'skip'] | translate }}
  </lib-button-form-field>
  <a class="mt-4 cursor-pointer" (click)="createPassword.emit()">{{ ['password', 'create_password'] | translate }}</a>
</div>
