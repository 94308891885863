import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  signal,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute } from '@angular/router';
import { IdentityService, SelfcarePassService } from '@yol-digital/ms-client';
import { DynamicHooksModule } from 'ngx-dynamic-hooks';
import { NgxMaskDirective } from 'ngx-mask';
import { EMPTY, Subject, tap, throwError } from 'rxjs';
import { catchError, filter, take, takeUntil } from 'rxjs/operators';
import { AnalyticsService } from 'analytics';
import { AuthService, RequestCodePayload } from 'auth-data-access';
import { BrandService } from 'brand';
import { FeatureFlagService } from 'feature-flag';
import {
  ButtonFormFieldComponent,
  emailPhoneValidator,
  FormFieldComponent,
  PasswordStrengthFormFieldComponent,
} from 'form-field';
import { SvgComponent } from 'icon';
import { MsApiResponseErrorCodes } from 'interfaces';
import { LanguageService } from 'language';
import { RedirectService } from 'redirect';
import { NgxSprinklrWebwidgetService } from 'sprinklr-webwidget';
import { TimerComponent, TimerService } from 'timer';
import { ToastService } from 'toast';
import { TooltipComponent } from 'tooltip';
import { TranslatePipe, TranslateService } from 'translate';
import { BrowserService, MetaService, StateService, StorageKeys } from 'utils';
import { NgxZendeskWebwidgetService } from 'zendesk-webwidget';
import { CreatePasswordDisclaimerComponent } from '../create-password-disclaimer/create-password-disclaimer.component';
import { ResetPasswordDisclaimerComponent } from '../reset-password-disclaimer/reset-password-disclaimer.component';
type MethodEnum = IdentityService.MethodEnum;
type SelfcareMethodEnum = SelfcarePassService.MethodEnum;

const ENABLE_LOGIN_CODE_ATTEMPTS = process.env.NODE_ENV === 'test';

type LoginStep =
  | 'EMAILPHONE'
  | 'CODE'
  | 'PASSWORD'
  | 'SET_PASSWORD_DISCLAIMER'
  | 'RESET_PASSWORD_DISCLAIMER'
  | 'SET_PASSWORD'
  | 'RESET_PASSWORD';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslatePipe,
    DynamicHooksModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TooltipComponent,
    SvgComponent,
    FormFieldComponent,
    ButtonFormFieldComponent,
    TimerComponent,
    CreatePasswordDisclaimerComponent,
    PasswordStrengthFormFieldComponent,
    ResetPasswordDisclaimerComponent,
  ],
  selector: 'lib-login',
  templateUrl: './login-form.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit, OnDestroy {
  private platformId = inject(PLATFORM_ID);
  private zone = inject(NgZone);
  private languageService = inject(LanguageService);
  ngxZendeskWebWidgetService = inject(NgxZendeskWebwidgetService);
  private translateService = inject(TranslateService);
  private toastService = inject(ToastService);
  private authService = inject(AuthService);
  private route = inject(ActivatedRoute);
  private browserService = inject(BrowserService);
  private analytics = inject(AnalyticsService);
  private stateService = inject(StateService);
  private redirectService = inject(RedirectService);
  brand = inject(BrandService);
  private meta = inject(MetaService);
  timerService = inject(TimerService);
  private sprinklrService = inject(NgxSprinklrWebwidgetService);
  featureFlagService = inject(FeatureFlagService);
  @ViewChild('codeInputField') codeInputField: FormFieldComponent;
  @ViewChild('emailPhoneInputField') emailPhoneInputField: FormFieldComponent;
  @ViewChild('passwordInputField') passwordInputField: FormFieldComponent;
  @Input() heroName?: string;
  @Input() email: string;
  @Input() redirectPath: string;
  @Input() keepPageMetaTitle?: boolean;
  _destroyed = new Subject<void>();
  loginForm: FormGroup;
  isTv = false;
  checkIcon?: string | undefined;
  validators = {
    emailphone: [emailPhoneValidator],
    code: [Validators.pattern(/^\d{6}$/)],
  };
  pathToRedirect = `${this.languageService.current}/account`;
  redirectParams = '';
  oidcJwt: string;
  loading = signal(false);
  codeValidationAttempts = 0;
  emailphonePlaceholderTranslate: string[];
  emailPhoneLabelTranslate: string[];
  codeCustomPatterns: NgxMaskDirective['patterns'] = {
    '0': { pattern: new RegExp('[0-9]') },
  };
  public timeRemaining: number;
  public isPasswordFound = signal(false);
  step = signal<LoginStep>('EMAILPHONE');
  password = signal<string>(null);
  title = computed<string>(() => {
    switch (this.step()) {
      case 'SET_PASSWORD_DISCLAIMER':
        return null;
      case 'RESET_PASSWORD_DISCLAIMER':
        return null;
      case 'SET_PASSWORD':
        return this.translateService.getTranslation(['password', 'create_password']);
      case 'RESET_PASSWORD':
        return this.translateService.getTranslation(['password', 'reset_password']);
      default:
        return this.translateService.getTranslation(['login', 'title']);
    }
  });
  showBackButton = computed(() => this.step() !== 'EMAILPHONE' && this.step() !== 'SET_PASSWORD_DISCLAIMER');
  showLoginForm = computed(() => ['EMAILPHONE', 'CODE'].includes(this.step()));
  showCodeField = computed(() => this.step() === 'CODE');
  showEmailPhoneField = computed(() => this.step() === 'EMAILPHONE');
  showSupportOptions = this.showEmailPhoneField;
  showPasswordDisclaimer = computed(() => this.step() === 'SET_PASSWORD_DISCLAIMER');
  showSetPassword = computed(() => this.step() === 'SET_PASSWORD');
  showResetPassword = computed(() => this.step() === 'RESET_PASSWORD');
  showResetPasswordDisclaimer = computed(() => this.step() === 'RESET_PASSWORD_DISCLAIMER');

  constructor() {
    const route = this.route;
    const timerService = this.timerService;

    this.languageService.onLangChange.pipe(takeUntil(this._destroyed)).subscribe(l => {
      this.changeZendeskWebWidgetConfig(l);

      route.queryParamMap.subscribe(params => {
        if (params.has('redirectSearch')) {
          try {
            const redirectSearch = JSON.parse(params.get('redirectSearch'));

            this.redirectParams =
              '?' +
              Object.keys(redirectSearch)
                .map(key => key + '=' + redirectSearch[key])
                .join('&');
          } catch (er) {
            console.warn('Invalid redirectSearch parameter');
          }
        }
        if (params.has('redirectPath')) {
          this.pathToRedirect = params.get('redirectPath');
        }

        if (params.has('oidcJwt')) this.oidcJwt = params.get('oidcJwt');
      });
    });

    timerService.timeRemaining$.subscribe(n => {
      this.timeRemaining = n;
      if (n === 0) {
        this.onResetTimer();
      }
    });
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      emailphone: new FormControl(this.email ?? '', [...this.validators.emailphone]),
      code: new FormControl(null, [...this.validators.code]),
      password: new FormControl(null),
    });
    this.route.data.pipe(takeUntil(this._destroyed)).subscribe(async data => {
      this.isTv = !!data?.isTv;
      this.checkIcon = this.isTv ? 'check-mark-tv' : undefined;
      this.emailphonePlaceholderTranslate = this.isTv ? ['login', 'tv_email_placeholder'] : ['login', 'placeholder'];
      this.emailPhoneLabelTranslate = this.isTv ? ['login', 'tv_email_label'] : ['login', 'phone_number_or_email'];
    });
    if (!this.keepPageMetaTitle) {
      this.meta.setTitle(this.translateService.getTranslation(['login', 'title']));
    }

    if (this.featureFlagService.isOn('sprinklr_chat')) {
      this.sprinklrService.init();
    } else {
      this.ngxZendeskWebWidgetService.initZendesk();
    }
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get codeRequestButtonDisable() {
    return this.isPasswordFound()
      ? !this.passwordControl.value || this.passwordControl.invalid
      : !this.emailphoneControl.value || this.emailphoneControl.invalid;
  }

  get passwordControl() {
    return this.loginForm.get('password');
  }

  get emailphoneControl() {
    return this.loginForm.get('emailphone');
  }

  get codeControl() {
    return this.loginForm.get('code');
  }

  get codePlaceholderTranslationKey() {
    return this.method == 'email' ? ['login', 'received_code'] : ['login', 'received_sms'];
  }

  get method(): MethodEnum | SelfcareMethodEnum {
    const emailPhoneValue = this.emailphoneControl?.value;
    return emailPhoneValue?.includes('@') ? 'email' : 'phone';
  }

  get btnClasses() {
    return this.isTv
      ? [
          '[&>button]:!text-black',
          '[&>button]:!bg-[--color-selective-yellow]',
          'disabled:[&>button]:!opacity-100',
          'disabled:[&>button]:!bg-[#1b1236]',
          'disabled:[&>button]:!text-[--color-gray-2]',
          'disabled:[&>button]:!border-[--color-thistle-purple]',
          'disabled:[&>button]:!border',
          'disabled:[&>button]:!border-solid',
        ]
      : ['disabled:[&>button]:!bg-[--color-gray-2]'];
  }

  get tvInputClasses() {
    return this.isTv
      ? [
          // eslint-disable-next-line no-useless-escape
          '[&_.mdc-text-field:not(.mdc-text-field--disabled)_.mat-mdc-input-element]:!text-white',
          '[&.ng-valid_.mdc-text-field:not(.mdc-text-field--disabled)_.mdc-floating-label]:!text-[--color-selective-yellow]',
          '[&_.mdc-text-field:not(.mdc-text-field--invalid)_.mat-mdc-input-element]:caret-[--color-selective-yellow]',
          '[&_.mat-mdc-input-element]:!text-white',
          '[&_.mdc-text-field--disabled_.mdc-notched-outline]:!text-[--color-judge-gray]',
          '[&_.mdc-text-field--disabled_.mdc-floating-label]:!text-[--color-judge-gray]',
          '[&.custom-form-field_input.mat-mdc-input-element::placeholder]:text-[--color-thistle-purple]',
          '[&.custom-form-field_.mdc-text-field--disabled_.mdc-notched-outline>*]:bg-transparent',
          'text-white',
          'stroke-[--color-selective-yellow]',
          '[&:not(.ng-valid)_.mdc-text-field--focused:not(.mdc-text-field--invalid)_.mdc-notched-outline>*]:!border-[--color-selective-yellow]',
          '[&:not(.ng-valid)_.mdc-text-field--focused:not(.mdc-text-field--invalid)_.mdc-floating-label]:!text-[--color-selective-yellow]',
          '[&_.mat-form-field:not(.mdc-text-field--invalid)_.mat-form-field-outline]:!text-[--color-thistle-purple]',
          '[&_.mat-form-field:not(.mdc-text-field--invalid)_.mdc-floating-label]:!text-[--color-thistle-purple]',
          '[&_.mat-mdc-input-element:disabled]:!text-[--color-thistle-purple]',
          '[&.custom-form-field:not(.mdc-text-field--focused)_.mdc-notched-outline>div]:!border-[--color-selective-yellow]',
          '[&_mdc-text-field--focused .mdc-notched-outline__trailing]: !text-[--color-selective-yellow]',
          '[&_.mat-form-field-appearance-outline:not(.mat-form-field-invalid):not(.mat-focused)_.mdc-notched-outline>div]:!border-[--color-thistle-purple]',
          '[&_.mat-form-field-appearance-outline:not(.mat-form-field-invalid):not(.mat-focused)_.mdc-notched-outline>div]:!text-[--color-thistle-purple]',
          '[&_.mat-form-field-appearance-outline:not(.mat-form-field-invalid):not(.mat-focused)_.mdc-floating-label]:!text-[--color-thistle-purple]',
        ]
      : [];
  }

  handlePasswordFound() {
    this.isPasswordFound.set(true);
    setTimeout(() => {
      this.passwordInputField.focus();
    });
    this.passwordControl.setValidators([Validators.required, Validators.minLength(8)]);
  }

  handleShowCodeInput() {
    this.step.set('CODE');
    this.emailphoneControl.disable();
    this.loading.set(false);
    this.timerService.startTimer();
    setTimeout(() => {
      this.codeInputField.focus();
    });
    this.codeControl.setValidators([Validators.required]);
  }

  public requestCode() {
    if (this.loading()) return;
    this.loading.set(true);
    const payload: RequestCodePayload = {
      value: this.emailphoneControl?.value,
      method: this.method,
    };

    if (this.isPasswordFound()) {
      payload.password = this.passwordControl.value;
    }
    this.codeControl.enable();
    this.authService
      .login(payload, this.isTv)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error.error === 'ERR_PASSWORD_REQUIRED') {
            this.handlePasswordFound();
          } else {
            this.displayErrorMessage(err.error.error);
          }
          this.loading.set(false);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.handleShowCodeInput();
      });
  }

  public login() {
    if (this.loading()) return;
    this.loading.set(true);
    if (this.redirectPath) {
      this.pathToRedirect = this.redirectPath;
    }
    this.authService
      .validate(this.codeControl?.value, this.isTv)
      .pipe(
        catchError(err => this.validateCodeAttempts(err)),
        catchError(err => this.resetLogin(err))
      )
      .subscribe(() => {
        if (this.isTv) {
          this.authService
            .authComplete(this.oidcJwt)
            .pipe(
              catchError(() =>
                this.resetLoginForTv(this.translateService.getTranslation(['login', 'error', 'generic']))
              ),
              tap(response => {
                if (response?.errorCode === 'ERR_NO_ACTIVE_TV_SUBSCRIPTION_FOUND') {
                  this.resetLoginForTv(
                    this.translateService.getTranslation(['login', 'error', 'ERR_NO_ACTIVE_TV_SUBSCRIPTION_FOUND'])
                  );
                }
              })
            )
            .subscribe(response => {
              if (response?.data?.redirectUrl) {
                this.redirectService.redirectTo(response.data.redirectUrl);
              }
            });
        } else {
          const message = this.translateService.getTranslation(['login', 'success.message']);
          this.toastService.add(message);
          this.loading.set(false);
          const data = {
            event: 'visitor interaction',
            event_category: 'login',
            event_action: 'success',
            event_label: this.stateService.get(StorageKeys.AccountId),
          };
          this.analytics.customEvent(data);
          if (this.featureFlagService.isOn('selfcare-mfa') && !this.isPasswordFound()) {
            this.step.set('SET_PASSWORD_DISCLAIMER');
          } else this.redirectAfterLogin();
        }
      });
  }

  private validateCodeAttempts(res: HttpErrorResponse) {
    // TODO: stays on standby to be checked with Daniel
    if (!ENABLE_LOGIN_CODE_ATTEMPTS) {
      return throwError(() => res);
    }
    //Continues flow after user fails more than 3 attempts
    ++this.codeValidationAttempts;
    this.loading.set(false);
    if (this.codeValidationAttempts < 3) {
      this.displayErrorMessage(res.error.error);
      return EMPTY;
    }
    this.codeValidationAttempts = 0;
    return throwError(() => res);
  }

  private onResetTimer() {
    this.codeValidationAttempts = 0;
    this.codeControl.reset();
    this.codeControl.disable();
  }

  private resetLogin(res: HttpErrorResponse) {
    this.displayErrorMessage(res.error.error);
    this.step.set('EMAILPHONE');
    this.emailphoneControl.enable();
    this.loginForm.reset();
    this.timerService.removeTimer();
    setTimeout(() => {
      this.emailPhoneInputField.focus();
    });
    this.loading.set(false);

    return EMPTY;
  }

  private resetLoginForTv(message: string) {
    this.toastService.add(message, false);
    this.step.set('EMAILPHONE');
    this.emailphoneControl.enable();
    this.loginForm.reset();
    this.timerService.removeTimer();
    setTimeout(() => {
      this.emailPhoneInputField.focus();
    });
    this.loading.set(false);

    return EMPTY;
  }

  private displayErrorMessage(error: MsApiResponseErrorCodes) {
    let message = this.translateService.getTranslation(['login', 'error', error.toLowerCase()]);
    if (error === 'ERR_INVALID_SECRET_OR_CODE' || error === 'ERR_PASSWORD_NOT_MATCHED') {
      const methodTranslation = this.translateService.getTranslation(['login', 'method_' + this.method]);
      message = message && message.replace('{{method}}', methodTranslation);
    }

    if (!message) {
      message = this.translateService.getTranslation(['login', 'error', 'generic']);
    }

    this.toastService.add(message, false);
  }

  resetCode() {
    this.timerService.removeTimer();
    this.codeControl.enable();
    this.requestCode();
  }

  back() {
    if (this.step() === 'SET_PASSWORD') this.step.set('SET_PASSWORD_DISCLAIMER');
    else {
      this.timerService.removeTimer();
      this.step.set('EMAILPHONE');
      this.emailphoneControl.enable();
      setTimeout(() => this.emailPhoneInputField.focus());
    }
  }

  private changeZendeskWebWidgetConfig(lang: string) {
    if (this.ngxZendeskWebWidgetService.isInitialized) {
      this.ngxZendeskWebWidgetService.zE('webWidget', 'setLocale', lang);
      this.ngxZendeskWebWidgetService.zE('webWidget', 'updateSettings', {
        webWidget: {
          chat: {
            concierge: {
              name: this.translateService.getTranslation(['zendesk.chat.concierge.name']),
              title: {
                '*': 'Please start conversation',
                [lang]: this.translateService.getTranslation(['zendesk.chat.concierge.title']),
              },
            },
            title: {
              '*': 'Support',
              [lang]: this.translateService.getTranslation(['zendesk.chat.title']),
            },
          },
        },
      });
    }
  }

  openChat() {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        if (this.featureFlagService.isOn('sprinklr_chat')) {
          this.sprinklrService.chat('enable');
          this.sprinklrService.chat('open');

          this.sprinklrService.events$
            .pipe(
              filter(e => e === 'CONVERSATION_WINDOW_CLOSED'),
              take(1)
            )
            .subscribe(() => {
              this.sprinklrService.chat('disable');
            });
        } else {
          if (!this.ngxZendeskWebWidgetService.isInitialized) return;
          this.changeZendeskWebWidgetConfig(this.languageService.current);
          this.ngxZendeskWebWidgetService.zE('webWidget', 'show');
          this.ngxZendeskWebWidgetService.zE('webWidget', 'open');
          this.ngxZendeskWebWidgetService.zE('webWidget:on', 'close', () => {
            this.ngxZendeskWebWidgetService.zE('webWidget', 'hide');
          });
        }
      });
    }
  }

  setPassword($event: string) {
    this.password.set($event);
  }

  submitPassword() {
    this.loading.set(true);
    this.authService
      .setPassword(this.password())
      .pipe(
        catchError(err => {
          console.error(err);
          const errorCode = err?.error?.error;
          const errorMsg = this.translateService.getTranslation(errorCode ? ['password', errorCode] : ['server_error']);
          this.toastService.add(errorMsg, false);
          this.loading.set(false);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.toastService.add(this.translateService.getTranslation(['password', 'password_set']));
        this.loading.set(false);
        this.redirectAfterLogin();
      });
  }

  requestResetPassword() {
    this.loading.set(true);
    const payload = {
      value: this.emailphoneControl?.value,
      method: this.method,
    };
    this.authService
      .requestResetPassword(payload.method as SelfcareMethodEnum, payload.value)
      .pipe(
        catchError(err => {
          console.error(err);
          this.authService.handleSelfcarePassError(err);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.step.set('RESET_PASSWORD_DISCLAIMER');
        this.loading.set(false);
      });
  }

  redirectAfterLogin() {
    this.browserService.redirect(this.pathToRedirect + this.redirectParams);
  }
}
