<div>
  <div class="container p-7 overflow-x-hidden">
    @if (type() === 'create') {
      <div class="flex flex-start cursor-pointer absolute mt-3" role="button" tabindex="0" (click)="back()">
        <lib-svg class="md:mr-1 mt-4 stroke-dark stroke-[var(--color-dark-gray)]" svg="arrow"></lib-svg>
        <p class="font-bold text-sm hidden md:block mt-3.5">
          {{ ['back'] | translate }}
        </p>
      </div>
    }
    <div class="max-w-md mx-auto">
      @if (type() === 'create' && this.showDisclaimer()) {
        <lib-create-password-disclaimer
          (skip)="redirectAfterLogin()"
          (createPassword)="hideDisclaimer()"
        ></lib-create-password-disclaimer>
      } @else {
        <form>
          <div class="flex justify-center mt-4">
            <h2 class="leading-none mb-2">
              {{ ['password', titleKey() ?? 'create_password'] | translate }}
            </h2>
          </div>

          <div class="lg:col-span-3 mt-12">
            <lib-password-strength-form-field
              (passwordValue)="setPassword($event)"
              [disabled]="loading()"
            ></lib-password-strength-form-field>
          </div>

          <div class="flex flex-col items-center mt-12">
            <lib-button-form-field
              type="submit"
              size="full-width"
              class="w-full lg:col-span-3 lg:pt-1.5"
              [disabled]="!password() || loading()"
              [loading]="loading()"
              (click)="type() === 'create' ? submitPassword() : resetPassword()"
            >
              {{ ['login', 'login_button_label'] | translate }}
            </lib-button-form-field>
          </div>
        </form>
      }
    </div>
  </div>
</div>
