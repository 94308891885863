<div [ngClass]="{ 'bg-[var(--color-midnight)] bg-fixed': isTv }">
  <div id="login" class="container p-7 overflow-x-hidden">
    @if (showBackButton()) {
      <div class="flex flex-start cursor-pointer absolute mt-3" role="button" tabindex="0" (click)="back()">
        <lib-svg
          class="md:mr-1 mt-4 stroke-dark stroke-[var(--color-dark-gray)]"
          svg="arrow"
          [ngClass]="{ '!stroke-white ': isTv }"
        ></lib-svg>
        <p class="font-bold text-sm hidden md:block mt-3.5" [ngClass]="{ 'text-white': isTv }">
          {{ ['login', 'back'] | translate }}
        </p>
      </div>
    }

    <div class="max-w-md mx-auto">
      @if (title()) {
        <div class="flex justify-center mt-4">
          <h2 class="leading-none mb-2" [ngClass]="{ 'text-white': isTv }">
            {{ title() }}
          </h2>
          <tooltip
            [iconClass]="
              isTv
                ? 'md:mr-1 normal ' +
                  '[&_.primary-color]:fill-white [&_.secondary-color]:fill-black ' +
                  '[&.active_.primary-color]:fill-[var(--color-selective-yellow)] ' +
                  '[&.active_.secondary-color]:fill-white'
                : undefined
            "
          >
            <ngx-dynamic-hooks
              data-source="prismic"
              [content]="['login', 'instructions_placeholder'] | translate"
              [options]="{ sanitize: false }"
            >
            </ngx-dynamic-hooks>
          </tooltip>
        </div>
      }
      <ng-content></ng-content>

      @if (showLoginForm()) {
        <form [formGroup]="loginForm">
          <div class="lg:col-span-3 mt-12">
            <lib-input-form-field
              #emailPhoneInputField
              class="w-full flex-grow border-solid -mb-4"
              [ngClass]="tvInputClasses"
              [theme]="isTv ? 'accent' : 'primary'"
              [checkmarkIcon]="checkIcon"
              [autoFocus]="true"
              [label]="emailPhoneLabelTranslate | translate"
              type="text"
              formControlName="emailphone"
              data-testid="emailphone-input"
              [placeholder]="emailphonePlaceholderTranslate | translate"
            >
            </lib-input-form-field>

            @if (showCodeField()) {
              <lib-input-form-field
                class="w-full flex-grow border-solid -mb-6"
                #codeInputField
                [ngClass]="tvInputClasses"
                [theme]="isTv ? 'accent' : 'primary'"
                [checkmarkIcon]="checkIcon"
                [label]="['login', 'code_label'] | translate"
                maxlength="6"
                type="text"
                id="code"
                [placeholder]="codePlaceholderTranslationKey | translate"
                formControlName="code"
                data-testid="code-input"
                [mask]="'000000'"
                [maskCustomPatterns]="codeCustomPatterns"
              >
              </lib-input-form-field>
              <lib-timer [theme]="isTv ? 'white' : 'normal'" />
            }
            @if (isPasswordFound() && !showCodeField()) {
              <lib-input-form-field
                class="w-full flex-grow border-solid -mb-6"
                #passwordInputField
                [ngClass]="tvInputClasses"
                [theme]="isTv ? 'accent' : 'primary'"
                [checkmarkIcon]="checkIcon"
                [label]="['login_pos', 'password_label'] | translate"
                type="password"
                [showHidePassword]="true"
                [placeholder]="['login_pos', 'password_placeholder'] | translate"
                formControlName="password"
              >
              </lib-input-form-field>
              <a
                class="flex items-center justify-center text-primary font-bold underline cursor-pointer mt-8"
                (click)="requestResetPassword()"
              >
                {{ ['password', 'forget_password'] | translate }}
              </a>
            }
          </div>

          <div class="flex flex-col items-center mt-12">
            @if (showEmailPhoneField()) {
              <lib-button-form-field
                type="submit"
                size="full-width"
                class="w-full lg:col-span-3 lg:pt-1.5"
                [ngClass]="btnClasses"
                [disabled]="codeRequestButtonDisable"
                [loading]="loading()"
                data-testid="code-request-button"
                (click)="requestCode()"
                >{{ ['login', 'code_request'] | translate }}</lib-button-form-field
              >
            } @else {
              @if (timeRemaining === 0) {
                <lib-button-form-field
                  type="submit"
                  size="full-width"
                  class="w-full lg:col-span-3 lg:pt-1.5"
                  [ngClass]="btnClasses"
                  data-testid="new-code-request-button"
                  (click)="resetCode()"
                  >{{ ['login', 'new_code_request'] | translate }}</lib-button-form-field
                >
              } @else {
                <lib-button-form-field
                  type="submit"
                  size="full-width"
                  class="w-full lg:col-span-3 lg:pt-1.5"
                  [ngClass]="btnClasses"
                  [disabled]="codeControl.invalid"
                  [loading]="loading()"
                  data-testid="login-button"
                  (click)="login()"
                  >{{ ['login', 'login_button_label'] | translate }}</lib-button-form-field
                >
              }
            }

            @if (showSupportOptions()) {
              <div class="mt-6">
                <small class="font-medium items-center text-slate-600 leading-5" [ngClass]="{ 'text-white': isTv }">
                  <span>
                    <a
                      *ngIf="
                        featureFlagService.isOn('sprinklr_chat') || ngxZendeskWebWidgetService.isInitialized;
                        else spinner
                      "
                      (click)="openChat()"
                      class="underline cursor-pointer font-extrabold"
                    >
                      {{ ['login', 'chat'] | translate }}
                    </a>
                    <ng-template #spinner>
                      <img class="h-5 inline" src="/resources/small-spinner.gif" alt="" />
                    </ng-template>
                  </span>
                  {{ ['login', 'need_help'] | translate }}
                  <a
                    [href]="brand.isYallo() ? 'tel:0900004488' : 'tel:0900480000'"
                    class="underline cursor-pointer font-extrabold"
                  >
                    {{ brand.isYallo() ? '0900 00 44 88' : '0900 48 00 00' }}
                  </a>

                  {{ ['login', 'call_price'] | translate }}
                </small>
              </div>
            }
          </div>
        </form>
      }

      @if (showPasswordDisclaimer()) {
        <lib-create-password-disclaimer
          (skip)="redirectAfterLogin()"
          (createPassword)="step.set('SET_PASSWORD')"
        ></lib-create-password-disclaimer>
      }
      @if (showResetPasswordDisclaimer()) {
        <lib-reset-password-disclaimer></lib-reset-password-disclaimer>
      }
      @if (showSetPassword() || showResetPassword()) {
        <div class="lg:col-span-3 mt-12">
          <lib-password-strength-form-field
            (passwordValue)="setPassword($event)"
            [disabled]="loading()"
          ></lib-password-strength-form-field>
        </div>

        <div class="flex flex-col items-center mt-12">
          <lib-button-form-field
            type="submit"
            size="full-width"
            class="w-full lg:col-span-3 lg:pt-1.5"
            [disabled]="!password() || loading()"
            [loading]="loading()"
            (click)="submitPassword()"
          >
            {{ ['login', 'login_button_label'] | translate }}
          </lib-button-form-field>
        </div>
      }
    </div>
  </div>
</div>
